<template>
    <a-layout id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <CompanyHeader></CompanyHeader>
      </a-layout-header>
  
      <a-layout-content style="">
        <div class="content_banner">
         <img style="width: 50%;" src="../static/orderserver.jpg" />
        </div>
        
  
      </a-layout-content>
    </a-layout>
  </template>
  
  <script>
  import CompanyHeader from "./home/components/companyheader.vue";
  
  export default {
    name: "orderserver",
    components: {
      CompanyHeader,
    },
    created() {},
    data() {
      return {};
    },
  };
  </script>
  <style>
  /* 适配各种屏幕尺寸 */
  @media (min-width: 1024px) {
    body,
    html {
      font-size: 18px !important;
    }
  }
  @media (min-width: 1100px) {
    body,
    html {
      font-size: 20px !important;
    }
  }
  @media (min-width: 1280px) {
    body,
    html {
      font-size: 22px !important;
    }
  }
  @media (min-width: 1366px) {
    body,
    html {
      font-size: 24px !important;
    }
  }
  @media (min-width: 1440px) {
    body,
    html {
      font-size: 25px !important;
    }
  }
  @media (min-width: 1680px) {
    body,
    html {
      font-size: 28px !important;
    }
  }
  @media (min-width: 1920px) {
    body,
    html {
      font-size: 33px !important;
    }
  }
  </style>
  <style scoped lang="less">
  #components-layout-demo-top-side .header {
    height: 2.76rem;
    background-color: #fff;
    box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    padding: 0.63rem 1.78rem 0.6rem 1.7rem;
  }
  .content_banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  </style>